import "../../styles/Partners.css";

function StartupDiv2() {
  return (
    <div
      style={{
        backgroundColor: "grey",
        margin: "2em",
        color: "black",
        marginTop: "3em",
        borderRadius: "1em",
      }}
    >
      <div style={{ margin: "1em", paddingTop: "0.5em" }}>
        <h4>apply to partner with collison</h4>
        <form class="rjsf">
          <div>
            <div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div>{" "}
            <div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div>
            <div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div>
            <div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div>
            <div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div>
            <div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div><div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div><div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div><div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div><div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div><div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div><div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div><div style={{paddingBottom:"1em"}}>
              <input
                type="email"
                placeholder="Business Email Address"
                // aria-invalid="false"
                id="root_business_email_address"
                required=""
                // value=""
                className="MuiInput"
              />
              <hr style={{ marginTop: "-0.2em", backgroundColor: "orange" }} />
            </div>
            <div style={{ paddingTop: "1em" }}>
              <span>
                I allow Collision to customise the messages I'm shown, relevant
                to my interests, using targeted advertising based on the details
                submitted.*
              </span>
              <div className="input-container" style={{ paddingTop: "1em" }}>
                <div style={{ marginRight: "1em" }}>
                  {/* class="radioField"> */}
                  <input
                    type="radio"
                    id="field_94293034---5-0"
                    required=""
                    name="i_allow_collision_to_customise_the_messages_im_shown_relevant_to_my_interests_using_targeted_advertising_based_on_the_details_submitted"
                    value="Yes"
                  />
                  <label for="field_94293034---5-0">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="field_94293034---5-1"
                    required=""
                    name="i_allow_collision_to_customise_the_messages_im_shown_relevant_to_my_interests_using_targeted_advertising_based_on_the_details_submitted"
                    value="No"
                  />
                  <label for="field_94293034---5-1">No</label>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "1em" }}>
              <span>
                I allow Collision to customise the messages I'm shown, relevant
                to my interests, using targeted advertising based on the details
                submitted.*
              </span>
              <div className="input-container" style={{ paddingTop: "1em" }}>
                <div style={{ marginRight: "1em" }}>
                  {/* class="radioField"> */}
                  <input
                    type="radio"
                    id="field_94293034---5-0"
                    required=""
                    name="i_allow_collision_to_customise_the_messages_im_shown_relevant_to_my_interests_using_targeted_advertising_based_on_the_details_submitted"
                    value="Yes"
                  />
                  <label for="field_94293034---5-0">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="field_94293034---5-1"
                    required=""
                    name="i_allow_collision_to_customise_the_messages_im_shown_relevant_to_my_interests_using_targeted_advertising_based_on_the_details_submitted"
                    value="No"
                  />
                  <label for="field_94293034---5-1">No</label>
                </div>
              </div>
            </div>
            <button className="btn">Sign up for our newsletter</button>

          </div>
        </form>
      </div>
    </div>
  );
}

export default StartupDiv2;
