function StartupDiv1() {
    return (
      <div style={{ color: "black",marginTop: "3em" , margin:"1.5em"}}>
<div>
  <h1>STARTUP? APPLY FOR OUR STARTUP PROGRAM, ALPHA, IN 2024.</h1>
  <p>Collision shines a light on early-stage startups with outstanding potential, connecting them with the world’s most influential names.
Please note applications for 2023 have closed. You can now apply for our 2024 ALPHA startup program.</p>
 

</div>
      </div>
    );
  }
  
  export default StartupDiv1;
