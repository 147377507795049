// import Hero from '../Home/Hero';
// import Section2 from './Section2';
// import Slider from './Slider';
// import Speaker2 from './Speaker2';
// import DownloadSection from '../Home/DownloadSection';
// import Slider2 from './Slider2';
// import Section3 from './Section3';
// import Section4 from './Section4';
// import Section5 from './Section5';
// import Section6 from './Section6';
// import Footer from './Footer';

import Footer from "../Home/Footer";
import Header from "../Home/Header";
import Hero from "../Home/Hero";
import Section3 from "../Home/Section3";
import Section4 from "../Home/Section4";
import Section6 from "../Home/Section6";
import Slider from "../Home/Slider";
import Slider2 from "../Home/Slider2";
import HeroPartner from "./HeroPartner";

function MeetPartners() {
    return (

        //for now we are 
      <div>
      <div style={{ width: "100%" }}>
      <Header />
    </div>

    <HeroPartner/>

    <p>Partners apper here</p>
    <Footer />

    {/* 
    <Section2/>
    <Speaker2/>
    <DownloadSection/>
  
    <Section5/>
    */}

    </div>    );
  }
  
  export default MeetPartners;