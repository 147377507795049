import GroupDiv1 from "./Groupdiv1";
import GroupDiv2 from "./Groupdiv2";
import GroupDiv3 from "./Groupdiv3";

function GroupOffer() {
    return (
      <div>
          <GroupDiv1/>
    <GroupDiv2/>
    <GroupDiv3/>

      </div>
  
    );
  }
  
  export default GroupOffer;