


const Section5 = () => {
    return (
    <section   className="text-size">
        <div>
        <div class="container"><h2 style={{color:"black"}}>Watch the Collision 2023 trailer </h2></div>
        <div class="container"><iframe width="100%" height="300" srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
              <a href='https://www.youtube.com/embed/4lb3D4Q5qM8?enablejsapi=1&amp;version=3&amp;playerapiid=ytplayer'>
              <img src='https://web-summit-library.imgix.net/collision/2222/07/collision-trailer-thumbnail-evergreen.jpeg?auto=compress%2Cformat&amp;ixlib=php-3.3.0&amp;s=51bf319b1cc5ffcfcbbd7e26d3c82c5f' alt='undefined'>
              <span>▶</span></a>" frameborder="0" allow="encrypted-media" allowfullscreen="" id="youtubeModalVideo" loading="lazy"></iframe></div>
        </div>
        </section>
        )}

export default Section5