import Footer from "../Home/Footer";
import Header from "../Home/Header";
import Hero from "../Home/Hero";
import Section3 from "../Home/Section3";
import Section6 from "../Home/Section6";
import Slider2 from "../Home/Slider2";
import Speaker2 from "../Home/Speaker2";
import HeroJob from "./HeroJob";


function Jobs() {
    return (
      <div>
      <div style={{ width: "100%" }}>
      <Header/>
    </div>
    <HeroJob/>
    <p> Apply to get jobs</p>



    <Footer />

    </div>    );
  }
  
  export default Jobs;